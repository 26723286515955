<template>
  <a-modal :visible="visible" title="子工单" :width="1200" :footer="null" @cancel="cancel">
    <a-table rowKey="id" size="small" :columns="columns" :data-source="items" :pagination="false" :scroll="{ x: 1400 }">
      <div slot="number" slot-scope="value, item">
        <a @click="detial(item)">{{ value }}</a>
      </div>
    </a-table>
  </a-modal>
</template>

<script>
export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          width: 180,
        fixed: "left",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "类型",
          dataIndex: "type_display",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status_display",
          width: 100,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "quantity_produced",
          width: 100,
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 180,
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 180,
        },
      ],
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
  },
};
</script>

<style scoped></style>
